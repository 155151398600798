import { render, staticRenderFns } from "./Cert.vue?vue&type=template&id=13245f14&"
import script from "./Cert.vue?vue&type=script&lang=js&"
export * from "./Cert.vue?vue&type=script&lang=js&"
import style0 from "./Cert.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports