<template>
    <div>
        <!-- 面包屑 -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>基本信息</el-breadcrumb-item>
            <el-breadcrumb-item>资质证件</el-breadcrumb-item>
        </el-breadcrumb>
        <!-- menu -->
        <el-row>
            <el-button  v-if="editInfo" @click="addTitle" type="primary" size="small" icon="el-icon-plus">添加</el-button>
            <el-button @click="searchBox" icon="el-icon-search" size="small">搜索</el-button>
        </el-row>
        <!-- menu-end -->
        <div class="cert-box">
            <el-tabs :tab-position="tabPosition" v-model="activeName" style="height: 100%" v-loading="loading">
                <el-tab-pane v-for="(item,index) in certList" :key="item.cid" :label="item.name" :name="item.name">
                    <!-- table -->
                    <el-table
                        :data="item.list"
                        border
                        style="width: 100%">
                        <el-table-column
                        type="selection"
                        width="55">
                        </el-table-column>
                        <el-table-column
                        prop="time"
                        label="日期">
                        </el-table-column>
                        <el-table-column
                        prop="title"
                        :highlight-current-row="true"
                        label="名称">
                        </el-table-column>
                        <el-table-column
                        prop="src"
                        label="图片">
                            <template slot-scope="scope">
                                <el-image
                                    style="width: 100px; height: 100px"
                                    :src="imgUrl+scope.row.src"
                                    :preview-src-list="srcList"
                                    fit="fit"></el-image>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="100" v-if="editInfo">
                        <template slot-scope="scope">
                            <el-button
                            size="mini"
                            type="danger"
                            :disabled="!editInfo"
                            @click="handleDelete(scope.$index, scope.row, index)">删除</el-button>
                        </template>
                        </el-table-column>
                    </el-table>
                    <!-- table-end -->
                    <div class="add-box" v-if="editInfo">
                        <el-button @click="addItem(index)" :disabled="!editInfo"
                        plain round icon="el-icon-plus" :index="index">
                            添加
                        </el-button>
                    </div>
                    <!-- addBtn -->
                </el-tab-pane>
            </el-tabs>
        </div>
        <!-- 添加资质 -->
        <el-dialog :title="this.CONST.ADD_CERT+'-'+curTitle" 
        :visible.sync="drawer">
            <AddCert />
        </el-dialog>
        <!-- search -->
        <el-dialog title="搜索" 
        :visible.sync="showSearch">
            <el-input placeholder="请输入内容" v-model="inputKey" class="input-with-select">
                <el-select v-model="select" slot="prepend" placeholder="请选择">
                    <el-option v-for="(item,index) in certList" :key="index" :label="item.name" :value="index"></el-option>
                </el-select>
                <el-button slot="append" icon="el-icon-search" @click="searchCert"></el-button>
            </el-input>
        </el-dialog>

    </div>
</template>
<script>
import {checkRules} from '@/utils/util.js'
import AddCert from '@/components/AddCert.vue'
export default {
    components:{
        AddCert
    },
    data(){
        return{
            editInfo:checkRules(15),//系统信息编辑权限
            select:'',
            inputKey:'',//搜索关键字
            showSearch:false,
            drawer:false,
            activeName:'',//显示的tab
            imgUrl:this.API.imgUrl,
            loading:true,
            tabPosition:'left',
            curTitle:'',//标题
            certList:[], //数据列表
            srcList:[] //图片列表
        }
    },
    created(){
        this.getCertList()
    },
    methods:{
        searchCert(){
            //console.log(this.select,this.inputKey)
            if(this.select==''){
                this.$message({
                        type: 'warning',
                        message: this.CONST.SAERCH_REQUIRED_SELECT
                }); 
                return
            }
            if(this.inputKey==''){
                this.certList=JSON.parse(localStorage.getItem("certList"))
                this.showSearch=false
                return
            }
            this.activeName=this.certList[this.select].name //跳转tab
            let that=this,list = this.certList[this.select].list,curList=[]
            list.map(function(item){
                if(item.title.indexOf(that.inputKey)!=-1){
                console.log(item.title)
                    curList.push(item)
                }
            })
            this.certList[this.select].list = curList
            this.showSearch = false
        },
        searchBox(){
            this.showSearch=true
        },
        //添加
        addItem(e){
        console.log(e)
           this.drawer=true
           this.curTitle=this.certList[e].name
        },
        //删除
        handleDelete(index,row,rindex){
            //console.log(index,row,rindex)
            this.$confirm(this.CONST.DEL_CONFIRM, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    //console.log(this.certList[rindex].list[index])
                    let list = this.certList[rindex].list 
                    list.splice(index,1)
                    this.$message({
                        type: 'success',
                        message: this.CONST.DEL_SUC
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.CONST.DEL_CANCEL
                    });          
                });
        },
        addTitle() {
            this.$prompt(this.CONST.INPUT_HINT_NAME, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消'
            }).then(({ value }) => {
                //console.log(value)
                //console.log(this.certList)
                this.certList.push({
                    cid:'',
                    firmid:this.certList[0].firmid,
                    list:[],
                    name:value,
                    type:0
                })
                this.activeName = value
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: this.CONST.DEL_CANCEL
                }); 
            });
       },
        getCertList(){
            let that=this,params = {
                method:"getCertList"
            }
            this.$ajax.post(this.API.api,params).then(res=>{
                //TODO
                //console.log(res.data)
                this.loading=false
                let srcList=[]
                res.data.forEach(function(item){
                    item.list = JSON.parse(item.list)
                    item.list.forEach(function(it){
                        srcList.push(that.imgUrl+it.src)
                    })
                })
                //console.log(res.data[0])
                this.activeName = res.data[0].name
                this.srcList = srcList
                this.certList = res.data
                localStorage.setItem('certList',JSON.stringify(res.data))
            }).catch(err=>{
                //Err
                //console.log(err)
                this.loading=false
                this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
                });
            })
        }
    }
}
</script>
<style lang="less">
.el-row{
    margin:10px 0;
}
.cert-box{
    border:1px #dbdbdb solid;
    border-radius: 5px;
    margin:10px 0;
    padding: 10px;
    min-height: 350px;
}
.add-box{
    width:100%;
    text-align: center;
    margin:10px 0;
}
</style>