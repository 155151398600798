<template>
<!-- 添加证件 -->
    <div class="add-cert">
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item :label="this.CONST.ADD_CERT_NAME">
                <el-input v-model="form.name" :placeholder="this.CONST.INPUT_HINT_NAME"></el-input>
            </el-form-item>
            <el-form-item :label="this.CONST.ADD_CERT_IMG">
               <!-- upload -->
                <el-upload
                    v-loading="loading"
                    class="avatar-uploader"
                    :on-error="uploadFail"
                    :before-upload="change"
                    :on-success="uploadSuccess"
                    :data="jsonData"
                    :action="actionUrl"
                    :show-file-list="false">
                    <img v-if="form.src" :src="form.src" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
               <!-- upload -->
            </el-form-item>
            <el-form-item >
                <el-button type="primary" @click="onSubmit">
                    提交</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
export default {
    data(){
        return{
            loading:false,
            jsonData:{
                method:'uploadImg'
            },
            actionUrl:this.API.api,
            form:{}
        }
    },
    created(){
      this.user = JSON.parse(sessionStorage.getItem('user'))
      this.actionUrl=this.actionUrl+'?token='+this.user.token
    },
    methods:{
        change(){
            this.loading=true
        },
        uploadFail(err){
            this.loading = false
            this.$message({
                    message: err.msg?err.msg:err,
                    type: 'warning'
            });
        },
        uploadSuccess(response){
            //console.log(response)
            this.loading = false
            if(response.status==0){
            //suc
            this.form.src = this.API.apiUrl+response.data 
            //console.log(this.form)
            }else{
            this.$message({
                message:response.msg,
                type: 'warning'
            });
            }
        },
        onSubmit(){
            //save
            let form=this.form
            if(!form.src){
                this.$message({
                    message: "请添加"+this.CONST.ADD_CERT_IMG,
                    type: 'warning'
                });
                return
            }
            if(!form.name||form.name.length<=1){
                this.$message({
                    message: "请添加"+this.CONST.ADD_CERT_NAME,
                    type: 'warning'
                });
                return
            }
        }
    }
}
</script>
<style lang="less">
.add-cert{
    padding: 10px ;

    .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>